import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { graphql } from 'gatsby';
import { borderWidths, colors, space } from '@entur/tokens';
import { Table, TableHead, TableRow, HeaderCell, TableBody } from '@entur/table';
import { GridContainer } from '@entur/grid';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import Colors from '~/components/Colors';
import PageHeader from '~/components/PageHeader';
import { ImageDisplay } from '~/components/ImageDisplay';
export const query = graphql`
query fargerQuery {
  files: allFile(filter: {sourceInstanceName: {eq: "media"}, relativeDirectory: {glob: "images/farger"}, extension: {eq: "png"}}) {
    images: nodes {
      name
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <PageHeader mdxType="PageHeader">
  Fargepaletten er et viktig grafisk virkemiddel i identiteten vår. Det er
  viktig at fargene brukes riktig og med omhu, for å oppnå et gjenkjennelig
  uttrykk.
    </PageHeader>
    <h2 {...{
      "id": "profilfarger"
    }}>{`Profilfarger`}</h2>
    <p>{`Når det er snakk om kjernekommunikasjon, skal vi prøve å forholde oss til disse hovedfargene.`}</p>
    <Colors colorType="brand" mdxType="Colors"></Colors>
    <h2 {...{
      "id": "funksjonsfarger"
    }}>{`Funksjonsfarger`}</h2>
    <p>{`Disse fargene er ikke direkte knyttet til identiteten til Entur,
men brukes til spesifikke funksjoner i grensesnittet som varselmeldinger, statusmeldinger eller validering i skjemaelementer.
Disse fargene er ikke ment som dekorasjon, og skal kun brukes til å formidle et budskap.`}</p>
    <Colors colorType="validation" mdxType="Colors" />
    <h2 {...{
      "id": "blåtoner"
    }}>{`Blåtoner`}</h2>
    <p>{`Disse blå fargenyansene brukes hovedsakelig som bakgrunnsfarger for
Contrast seksjoner og enkelte elementer i designet som for eksempel
borders og dividers.`}</p>
    <Colors colorType="blues" mdxType="Colors" />
    <h2 {...{
      "id": "gråtoner"
    }}>{`Gråtoner`}</h2>
    <p>{`Gråtonene symboliserer det tekniske og robuste i plattformen vår. Brukes
hovedsakelig som bakgrunnsfarger for å få frem det lyse og luftige ved
layouten i designet.`}</p>
    <Colors colorType="greys" mdxType="Colors" />
    <h2 {...{
      "id": "transportfarger"
    }}>{`Transportfarger`}</h2>
    <p>{`De ulike transportmidlene har hver sin representative farge for å skille
de fra hverandre. Vanngående transport har for eksempel alltid lyseblå
farge og er derfor også markert med den fargen på ikoner (ferje,
bilferje, cruise osv.) og på reiselinjer. Transportmiddelfargene finnes
også for Contrast seksjoner.`}</p>
    <Colors colorType="transport" mdxType="Colors" />
    <h2 {...{
      "id": "farger-for-datavisualisering"
    }}>{`Farger for datavisualisering`}</h2>
    <p>{`Vi har også en egen fargepalett som skal benyttes ved visualisering av data, i forskjellige typer grafer og andre visualiseringer av informasjon.
`}<a parentName="p" {...{
        "href": "/identitet/verktoykassen/datavisualisering"
      }}>{`Les mer om farger for datavisualisering her.`}</a></p>
    <Colors colorType="data" mdxType="Colors" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <h3 {...{
      "id": "vekting-av-profilfarger"
    }}>{`Vekting av profilfarger`}</h3>
    <p>{`Hvordan fargene vektes er en viktig del av å skape det ønskede uttrykket for Entur. Hovedfargene er blå, hvit og rød, og disse burde alltid være med. Korallfargen er vektet lavt, men er viktig og skal alltid være tilstede i uttak. Den brukes ofte til detaljer, til tider så detaljert som bare i logo.`}</p>
    <div style={{
      padding: '0.25rem',
      border: `2px solid ${colors.greys.grey70}`,
      marginBottom: space.extraLarge3,
      display: 'flex'
    }}>
  <ImageDisplay fluidSource={props.data.files.images.find(image => image.name === "ColorBalance")?.childImageSharp.fluid} alt="Viser vektleggingen av profilfargene, Blue og white er størst, så lavender, coral og til slutt peach" preset="full-width-image" mdxType="ImageDisplay" />
    </div>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Dont1")?.childImageSharp.fluid} alt="" noPadding mdxType="DoDontCard">
    Unngå mørk tekst på blå bakgrunn
  </DoDontCard>
  <DoDontCard variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Dont2")?.childImageSharp.fluid} alt="" noPadding mdxType="DoDontCard">
    Unngå korallrød bakgrunn
  </DoDontCard>
    </DoDontGroup>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Dont3")?.childImageSharp.fluid} alt="" noPadding mdxType="DoDontCard">
    Unngå identitetløst uttryk
  </DoDontCard>
  <DoDontCard variant="negative" fluidSource={props.data.files.images.find(image => image.name === "Dont4")?.childImageSharp.fluid} alt="" noPadding mdxType="DoDontCard">
    Unngå lys tekst på lys bakgrunn
  </DoDontCard>
    </DoDontGroup>
    <p>{`Les mer om kontrastkrav for farger på `}<a parentName="p" {...{
        "href": "/universell-utforming"
      }}>{`Universell utforming`}</a>{` siden og bruk vår `}<a parentName="p" {...{
        "href": "/universell-utforming/kontrast-sjekker"
      }}>{`Color Contrast Checker`}</a>{` for å hjelpe deg med å bestemme lesbarheten til tekst og kontrasten til grafiske objekter.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      